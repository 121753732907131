<template>
  <div>
    <h1 class="d-sr-only">{{ title }}</h1>
    <p class="d-sr-only" v-text="description" />

    <v-container :fluid="isFluid">
      <!-- First block: big carousel (carousel) + double side carousel (carousel2 carousel3) -->
      <v-row class="mb-0 mb-md-3">
        <v-col
          cols="12"
          md="8"
          class="pb-1 pb-sm-3 central-carousel carousel carousel-1"
        >
          <component
            v-if="carouselProposals.length > 0"
            :is="carouselMode"
            :title="carouselTitle"
            :proposals="carouselProposals"
            :autoplay="true"
            paginationClass="pagination-carousel"
          />
        </v-col>
        <v-col cols="12" md="4" class="side-carousel carousel">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="12"
              class="pt-0 pb-1 pt-sm-3 pb-sm-3 pb-md-0 carousel-2"
            >
              <component
                v-if="carousel2Proposals.length > 0"
                :is="carousel2Mode"
                :title="carousel2Title"
                :proposals="carousel2Proposals"
                :autoplay="true"
                paginationClass="pagination-carousel-2"
                :class="{
                  'mb-3':
                    $vuetify.breakpoint.xs && carousel2Proposals.length === 1
                }"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="12"
              class="pt-0 pb-1 pt-sm-3 pb-sm-3 pt-md-0 carousel-3"
            >
              <component
                v-if="carousel3Proposals.length > 0"
                :is="carousel3Mode"
                :title="carousel3Title"
                :proposals="carousel3Proposals"
                :autoplay="true"
                paginationClass="pagination-carousel-3"
                :class="{
                  'mb-3':
                    $vuetify.breakpoint.xs && carousel3Proposals.length === 1
                }"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Second block: carouse 4, 5 e 6 -->
      <v-row class="mt-0">
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pt-0 pb-1 pt-sm-3 pb-sm-3 carousel"
        >
          <component
            v-if="carousel4Proposals.length > 0"
            :is="carousel4Mode"
            :title="carousel4Title"
            :proposals="carousel4Proposals"
            :autoplay="true"
            paginationClass="pagination-carousel-4"
            :class="{
              'mb-3': $vuetify.breakpoint.xs && carousel4Proposals.length === 1
            }"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pt-0 pb-1 pt-sm-3 pb-sm-3 carousel"
        >
          <component
            v-if="carousel5Proposals.length > 0"
            :is="carousel5Mode"
            :title="carousel5Title"
            :proposals="carousel5Proposals"
            :autoplay="true"
            paginationClass="pagination-carousel-5"
            :class="{
              'mb-3': $vuetify.breakpoint.xs && carousel5Proposals.length === 1
            }"
          />
        </v-col>
        <v-col cols="12" md="4" class="pt-0 pb-1 pt-sm-3 pb-sm-3 carousel">
          <component
            class="force-product-card-mobile"
            v-if="carousel6Proposals.length > 0"
            :is="carousel6Mode"
            :title="carousel6Title"
            :proposals="carousel6Proposals"
            :autoplay="true"
            paginationClass="pagination-carousel-6"
            :cols="1"
            sm="auto"
            :md="1"
            :lg="1"
            :class="{
              'mb-3': $vuetify.breakpoint.xs && carousel6Proposals.length === 1
            }"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Blocco header: imperdibili -->
    <v-container :fluid="isFluid" class="header mb-3">
      <div class="mt-5">
        <component
          v-if="headerProposals.length > 0"
          :is="headerMode"
          :title="headerTitle"
          :proposals="headerProposals"
          :autoplay="true"
          paginationClass="pagination-header"
          cols="1"
          sm="auto"
          md="auto"
          lg="auto"
        />
      </div>
    </v-container>

    <!-- Blocco middle, nostro assortimento + product slider -->
    <div class="grey lighten-1 pb-4 mb-3 middle-wrapper">
      <v-container
        :fluid="isFluid"
        v-if="middle1Proposals.length > 0 || middle2Proposals.length > 0"
        class="middle"
      >
        <component
          class="mb-3"
          :is="middle1Mode"
          :title="middle1Title"
          :proposals="middle1Proposals"
          :autoplay="true"
          paginationClass="pagination-middle-1"
          cols="1"
          sm="2"
          :md="3"
          :lg="3"
          :showBullets="true"
        />

        <component
          :is="middle2Mode"
          :title="middle2Title"
          :proposals="middle2Proposals"
          :autoplay="true"
          paginationClass="pagination-middle-2"
          cols="1"
          sm="2"
          :md="3"
          :lg="3"
        />
      </v-container>
    </div>
    <!-- blocco footer 1: product slider birre -->
    <v-container
      :fluid="isFluid"
      class="footer footer-1"
      v-if="footer1Proposals.length > 0"
    >
      <component
        :is="footer1Mode"
        :title="footer1Title"
        :proposals="footer1Proposals"
        :autoplay="true"
        paginationClass="pagination-footer-1"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      />
    </v-container>

    <!-- blocco footer 2: novità tosano e solo da noi -->
    <v-container
      :fluid="isFluid"
      class="footer footer-2"
      v-if="footer2Proposals.length > 0"
    >
      <component
        :is="footer2Mode"
        :title="footer2Title"
        :proposals="footer2Proposals"
        :autoplay="true"
        paginationClass="pagination-footer-2"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .central-carousel {
    min-height: 220px;
  }
  .carousel-2.carousel-3.carousel-4.carousel-5 {
    min-height: 193px;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .central-carousel {
    min-height: 394px;
  }
  .carousel-2.carousel-3.carousel-4.carousel-5 {
    min-height: 166px;
  }
}
@media #{map-get($display-breakpoints, 'md-only')} {
  .central-carousel {
    min-height: 366px;
  }
  .carousel-2.carousel-3.carousel-4.carousel-5 {
    min-height: 170px;
  }
}
@media #{map-get($display-breakpoints, 'lg-only')} {
  .central-carousel {
    min-height: 450px;
  }
  .carousel-2.carousel-3.carousel-4.carousel-5 {
    min-height: 193px;
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .central-carousel {
    min-height: 730px;
  }
  .carousel-2.carousel-3.carousel-4.carousel-5 {
    min-height: 320px;
  }
}

.carousel {
  .proposal-img {
    border: 1px solid var(--v-grey-lighten1);
  }
}
.carousel-wrapper {
  .banner-slider {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
  }
}
.logo {
  // max-height: 60px;
  // max-width: 163px;
  @media #{map-get($display-breakpoints, 'md-only')} {
    // max-height: 50px;
    // max-width: 140px;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    max-height: 40px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    max-height: 30px;
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .central-carousel {
    .v-image {
      max-height: 731px !important;
    }
  }
  .side-carousel {
    .v-image {
      max-height: 355px !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-only')} {
  .central-carousel {
    .v-image {
      max-height: 484px !important;
    }
  }
  .side-carousel {
    .v-image {
      max-height: 231px !important;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-only')} {
  .central-carousel {
    .v-image {
      max-height: 366px !important;
    }
  }
  .side-carousel {
    .v-image {
      max-height: 171px !important;
    }
  }
  .middle-wrapper {
    .swiper-slide {
      h3.img-label {
        font-size: 30px;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .middle-wrapper {
    .swiper-slide {
      h3.img-label {
        font-size: 30px;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .footer.footer-1 {
    .product-slider {
      .product-card {
        width: 330px;
      }
    }
  }
  // .middle-wrapper {
  //   .swiper-wrapper {
  //     .proposal-img.v-card {
  //       max-width: 300px;
  //     }
  //   }
  // }
}
</style>
<script>
import split from "lodash/split";
import get from "lodash/get";

import ProductListSlider from "@/components/product/ProductListSlider.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";
import { mapProposalComponent } from "~/service/ebsn.js";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue"),
    ProductListSlider
  },
  data() {
    return {
      slides: {},
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      hasFavorites: "cart/hasFavorites"
    }),
    ...mapProposalComponent({
      carousel: "carousel",
      carousel2: "carousel_2",
      carousel3: "carousel_3",
      carousel4: "carousel_4",
      carousel5: "carousel_5",
      carousel6: "carousel_6",
      header: "header",
      middle1: "middle",
      middle2: "middle_2",
      footer1: "footer",
      footer2: "footer_2"
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    crmLayout() {
      return this.hasFavorites ? 29 : 26;
    },
    giftCodes() {
      if (this.isAuthenticated) {
        return this.user.giftCertificatesReceived || [];
      }
      return [];
    }
  },
  mounted() {
    console.log(this.carousel6Proposals);
    console.log(this.headerProposals);
  }
};
</script>

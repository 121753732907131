<template>
  <div class="slider product-slider" v-if="products && products.length > 0">
    <!-- :class="{ 'width-auto': $refs.swiperRef.options.slidesPerView == 'auto' }" -->
    <slot name="title"> </slot>
    <swiper :options="swiperOption" ref="swiperRef" v-if="products.length > 0">
      <swiper-slide v-if="hasFirstCardSlot">
        <slot name="firstCard" class="firstCard" />
      </swiper-slide>
      <swiper-slide v-for="product in products" :key="product.productId">
        <ProductCard v-bind:product="product" />
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && products.length > 1"
      :id="`product-list-pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && products.length > 1"
      :id="`product-list-slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && products.length > 1"
      :id="`product-list-slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
    <div v-if="showMore" class="text-right show-more">
      <v-btn
        link
        text
        class="secondary--text text-none"
        :to="{
          name: 'Category',
          params: { pathMatch: proposal.slug }
        }"
      >
        Vai alla categoria <v-icon>$next</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style global lang="scss">
.product-slider {
  .swiper-slide {
    max-width: 100%;
  }
  .product-card {
    width: 252px;
  }
  // &.width-auto {
  //   .product-card {
  //     width: 252px;
  //   }
  // }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      width: auto;
      min-width: 315px;
      h2 {
        color: #fff;
      }
    }
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductListSlider",
  props: {
    productList: { type: Array, required: false },
    query: { type: String, required: false },
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    autoplay: { type: Boolean, default: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true },
    position: { type: String, required: false }
  },

  components: { ProductCard },
  data() {
    return {
      products: [],
      showMore: false,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: this.autoplay,
        observer: true,
        observeParents: true,
        pagination: {
          el: `#product-list-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  async mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchProducts();
    if (this.products && this.products.length > 0) {
      analyticsService.viewProducts(this.products, this.position);
    }
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    async fetchProducts() {
      if (this.productList) {
        this.products = this.productList;
      } else if (this.query) {
        let response = await ProductService.search({
          q: this.query,
          page_size: 16
        });
        this.products = response.products;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        this.products = response.products;
      }
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"d-sr-only",domProps:{"textContent":_vm._s(_vm.description)}}),_c('v-container',{attrs:{"fluid":_vm.isFluid}},[_c('v-row',{staticClass:"mb-0 mb-md-3"},[_c('v-col',{staticClass:"pb-1 pb-sm-3 central-carousel carousel carousel-1",attrs:{"cols":"12","md":"8"}},[(_vm.carouselProposals.length > 0)?_c(_vm.carouselMode,{tag:"component",attrs:{"title":_vm.carouselTitle,"proposals":_vm.carouselProposals,"autoplay":true,"paginationClass":"pagination-carousel"}}):_vm._e()],1),_c('v-col',{staticClass:"side-carousel carousel",attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1 pt-sm-3 pb-sm-3 pb-md-0 carousel-2",attrs:{"cols":"12","sm":"6","md":"12"}},[(_vm.carousel2Proposals.length > 0)?_c(_vm.carousel2Mode,{tag:"component",class:{
                'mb-3':
                  _vm.$vuetify.breakpoint.xs && _vm.carousel2Proposals.length === 1
              },attrs:{"title":_vm.carousel2Title,"proposals":_vm.carousel2Proposals,"autoplay":true,"paginationClass":"pagination-carousel-2"}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0 pb-1 pt-sm-3 pb-sm-3 pt-md-0 carousel-3",attrs:{"cols":"12","sm":"6","md":"12"}},[(_vm.carousel3Proposals.length > 0)?_c(_vm.carousel3Mode,{tag:"component",class:{
                'mb-3':
                  _vm.$vuetify.breakpoint.xs && _vm.carousel3Proposals.length === 1
              },attrs:{"title":_vm.carousel3Title,"proposals":_vm.carousel3Proposals,"autoplay":true,"paginationClass":"pagination-carousel-3"}}):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0 pb-1 pt-sm-3 pb-sm-3 carousel",attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.carousel4Proposals.length > 0)?_c(_vm.carousel4Mode,{tag:"component",class:{
            'mb-3': _vm.$vuetify.breakpoint.xs && _vm.carousel4Proposals.length === 1
          },attrs:{"title":_vm.carousel4Title,"proposals":_vm.carousel4Proposals,"autoplay":true,"paginationClass":"pagination-carousel-4"}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0 pb-1 pt-sm-3 pb-sm-3 carousel",attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.carousel5Proposals.length > 0)?_c(_vm.carousel5Mode,{tag:"component",class:{
            'mb-3': _vm.$vuetify.breakpoint.xs && _vm.carousel5Proposals.length === 1
          },attrs:{"title":_vm.carousel5Title,"proposals":_vm.carousel5Proposals,"autoplay":true,"paginationClass":"pagination-carousel-5"}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0 pb-1 pt-sm-3 pb-sm-3 carousel",attrs:{"cols":"12","md":"4"}},[(_vm.carousel6Proposals.length > 0)?_c(_vm.carousel6Mode,{tag:"component",staticClass:"force-product-card-mobile",class:{
            'mb-3': _vm.$vuetify.breakpoint.xs && _vm.carousel6Proposals.length === 1
          },attrs:{"title":_vm.carousel6Title,"proposals":_vm.carousel6Proposals,"autoplay":true,"paginationClass":"pagination-carousel-6","cols":1,"sm":"auto","md":1,"lg":1}}):_vm._e()],1)],1)],1),_c('v-container',{staticClass:"header mb-3",attrs:{"fluid":_vm.isFluid}},[_c('div',{staticClass:"mt-5"},[(_vm.headerProposals.length > 0)?_c(_vm.headerMode,{tag:"component",attrs:{"title":_vm.headerTitle,"proposals":_vm.headerProposals,"autoplay":true,"paginationClass":"pagination-header","cols":"1","sm":"auto","md":"auto","lg":"auto"}}):_vm._e()],1)]),_c('div',{staticClass:"grey lighten-1 pb-4 mb-3 middle-wrapper"},[(_vm.middle1Proposals.length > 0 || _vm.middle2Proposals.length > 0)?_c('v-container',{staticClass:"middle",attrs:{"fluid":_vm.isFluid}},[_c(_vm.middle1Mode,{tag:"component",staticClass:"mb-3",attrs:{"title":_vm.middle1Title,"proposals":_vm.middle1Proposals,"autoplay":true,"paginationClass":"pagination-middle-1","cols":"1","sm":"2","md":3,"lg":3,"showBullets":true}}),_c(_vm.middle2Mode,{tag:"component",attrs:{"title":_vm.middle2Title,"proposals":_vm.middle2Proposals,"autoplay":true,"paginationClass":"pagination-middle-2","cols":"1","sm":"2","md":3,"lg":3}})],1):_vm._e()],1),(_vm.footer1Proposals.length > 0)?_c('v-container',{staticClass:"footer footer-1",attrs:{"fluid":_vm.isFluid}},[_c(_vm.footer1Mode,{tag:"component",attrs:{"title":_vm.footer1Title,"proposals":_vm.footer1Proposals,"autoplay":true,"paginationClass":"pagination-footer-1","cols":"auto","sm":"auto","md":"auto","lg":"auto"}})],1):_vm._e(),(_vm.footer2Proposals.length > 0)?_c('v-container',{staticClass:"footer footer-2",attrs:{"fluid":_vm.isFluid}},[_c(_vm.footer2Mode,{tag:"component",attrs:{"title":_vm.footer2Title,"proposals":_vm.footer2Proposals,"autoplay":true,"paginationClass":"pagination-footer-2","cols":"auto","sm":"auto","md":"auto","lg":"auto"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }